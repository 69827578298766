body {
  font-family: "Oxygen", sans-serif;
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  line-height: 1.2em;
  word-spacing: 2px;
  font-style: normal;
  color: black;
  background-color: #f7faf5;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

body>div {
  overflow-x: hidden;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: #509561;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #509561;
  border-bottom: 2px solid #509561;
  transition: border .2s ease-in-out;
}

.navbar-light .navbar-nav .nav-link {
  border-bottom: 2px solid rgba(248, 249, 250, 1);
}

.is-sticky {
  position: fixed;
  top: 0;
  /* margin: 0 8rem; */
  /* width: -webkit-fill-available; */
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  padding-top: 0px;
  padding-bottom: 0px;
  transition: all 0.5s ease;
  border-bottom: 4px solid #509561;
  animation: smoothScroll 1s forwards;
}

.is-sticky .header-logo {
  padding: 0 2rem;
}

.modal-title {
  color: rgb(80, 149, 97);
}

@media (max-width:800px) {
  .is-sticky {
    position: relative;
  }
}


@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

h1 {
  font-family: "Merriweather", serif;
  text-transform: capitalize;
  line-height: 1.1em;
  letter-spacing: normal;
  word-spacing: 2px;
  font-size: 2.8rem;
}

p {
  color: #535353;
  line-height: 1.5em;
  letter-spacing: 0.04em;
  font-size: 15px;
}

.container {
  padding: 5rem 4rem;
  overflow: hidden;
  width: 100%;
}

.banner {
  background-image: url('/public/assets/banner1.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.nav-item a {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 13px;
}

.header {
  position: absolute;
  top: 10px;
  padding: 0 8rem;
  width: 100%;
  z-index: 1;
}

.header-call {
  background-color: #509561;
  display: grid;
  align-items: center;
  color: white;
}

.header-call:hover {
  background-color: #327242;
}

.header-call-btn {
  color: white;
  font-size: 1.3rem;
  border: 1px solid white;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-us-now {
  font-size: 16px;
  line-height: 2em;
}

.section-title {
  font-size: 15px;
  font-weight: 600;
  color: #a1847f;
  letter-spacing: 0.05em;
}


/* -----home------- */
.home-content {
  margin: 40vh auto 0;
  transform: translateY(-50%);
}

.home-content h1 {
  /* letter-spacing: -2px; */
  font-size: 4.1rem;
  /* word-spacing: 2px; */
  color: #1d5b2c;
}

.home-content p {
  margin-top: 20px;
  font-size: 16px;
  text-transform: capitalize;
  color: #5f3f39;
}


/* --------about------- */
.about-imgs img {
  max-height: 57vh;
  width: 100%;
}

.about-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
}

.about-logo img {
  height: 9rem;
  width: 100%;
  border: 2px solid #7e615c;
  /* padding: 10px; */
}

.about-content p {
  margin-top: 20px;
  text-transform: capitalize;
}

.about-content {
  padding: 20px;
  display: grid;
  gap: 5px;
  letter-spacing: 0.02em;
}

.about-icon {
  color: #509561;
  font-size: 18px;

}

.about-icon-content {
  font-size: 15px;
  color: #535353;
  line-height: 1.2em;
}

/* --------Quote----- */
.quote-content h1 {
  color: #535353;
  line-height: 1.5em;
}

.quote-content h5 {
  padding-top: 10px;
  color: #3b5533;
  font-weight: 700;
}

/* ----------Services-------- */
.services-title h1 {
  padding: 10px 0;
}

.services {
  background-color: #e8f5dc;
  padding: 1.6rem;
  position: relative;
  cursor: pointer;
}

.service-logo {
  background-color: #509561;
  width: fit-content;
  padding: 13px;
  font-size: 2rem;
  color: white;
  margin-bottom: 13px;
}

.services h3 {
  font-family: "Merriweather", serif;
}

.service-arrow {
  width: fit-content;
  padding: 15px 18px;
  position: absolute;
  top: 0%;
  right: 0%;
  font-size: 1.5rem;
  color: #509561;
  border-style: solid;
  border-width: 0px 0px 5px 5px;
  border-color: #f7faf5;
}

.service-arrow:hover {
  color: #e8f5dc;
  background-color: #509561;
  transition: all .3s;
}

.read-more-btn {
  background-color: #509561;
  color: white;
  font-weight: 600;
  padding: 0.6rem 1.3rem;
  border: 2px solid #509561;
  letter-spacing: 0.1em;
  transition: all .3s ease-in-out;
}

.read-more-btn:hover {
  background-color: #e8f5dc;
  color: #509561;
  padding: 0.6rem 1.3rem;
}

.benefits li {
  line-height: 1.5em;
  color: #535353;
  margin: 10px 0;
}

.modernWay {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

dialog {
  background: none;
  border: 0;
  animation: fadeIn 1s ease both;

  &::backdrop {
    animation: fadeIn 1s ease both;

    z-index: 9999;
    backdrop-filter: blur(8px);
  }

  .x {
    /* filter: grayscale(1); */
    border: none;
    background: none;
    position: absolute;
    top: 18px;
    right: 18px;
    transition: ease filter, transform 0.3s;
    cursor: pointer;
    transform-origin: center;

    &:hover {
      filter: grayscale(0);
      transform: scale(1.1);
    }
  }

}

/* --------aapointment------ */
.aapointment {
  display: grid;
  justify-content: center;
  gap: 15px;
}

.aapointment h6 {
  color: #B2BE59;
  font-weight: 600;
}

.aapointment h1 {
  color: #f7faf5;
  /* max-width: 40rem; */
}

.aapointment p {
  color: #f7faf5;
  max-width: 40rem;
  font-weight: lighter;
}

.aapointment .call-btn {
  padding: 12px 15px;
  background-color: #5f3f39;
}

.aapointment .call-btn:hover {
  background-color: #515725;
  transition: all .3s;
}

.aapointment .book-btn button {
  text-decoration: none;
  color: white;
  letter-spacing: 0.05em;
  font-size: 17px;
  border: 2px solid white;
  padding: 10px;
  background-color: transparent;
}


.aapointment .book-btn:hover {
  /* border: 2px solid #5f3f39; */
  background-color: #5f3f39;
  transition: all .3s;
}

.appointment-btn {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.appointment-btn .submit-btn,
.appointment-btn .cancle-btn {

  color: white;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  width: fit-content;
  border-radius: 0%;
}

.appointment-btn .submit-btn {
  background-color: #509561;
}

.appointment-btn .cancle-btn {
  background-color: rgb(194, 69, 69);
}

.appointment-btn .submit-btn:hover {
  background-color: #205d2f;
}

.appointment-btn .cancle-btn:hover {
  background-color: rgb(136, 37, 37);
}

.treatements li {
  list-style: circle;
  color: white;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 1.5rem;
  font-size: 14px;
}

.treatement-list {
  display: flex;
  gap: 10px;
  height: 100%;
  background-color: rgba(255,255,255,0.7);
  margin: 10px 0;
  padding: 8px;
  border-radius: 20px;
  align-items: center;
  width: 16rem;
}

.treatement-list svg {
  color: #8a9440;
  font-size: 22px;
}

.treatement-list p {
  color: black;
  margin-bottom: 0%;
  font-weight: 700;
}

/* ------whyCooseUs---- */
.why-choose-bg {
  background-color: transparent;
  opacity: 1;
  background-image: radial-gradient(at center center, #02010100 0%, #FFFFFF 70%);
}

.whyChoose-check {
  background-color: #509561;
  width: fit-content;
  padding: 15px;
  font-size: 20px;
  color: white;
  height: fit-content;
}

.whyChoose-points h6 {
  font-weight: 600;
  font-family: "Merriweather", serif;
  font-size: 20px;
  line-height: 1.2em;
  font-style: normal;
  letter-spacing: normal;
  color: #2a5334;
}

.whyCooseUs {
  padding: 20px;
}

.whyCooseUs img {
  width: 100%;

}

.whyCooseUs .img-content {
  position: absolute;
  bottom: 0%;
  right: 0%;
  background-color: white;
  width: 60%;
  padding: 20px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

.whyCooseUs .img-content .content-border {
  border-left: 3px solid #509561;
  padding-left: 20px;
}

.data-read {
  color: #3a7f4b;
  text-decoration: underline;
  cursor: pointer;
}

.data-read:hover {
  color: rgb(219, 64, 64);
}

.content-border h6 {
  font-size: 20px;
  line-height: 1.5em;
  color: #535353;
}

.content-border p {
  font-weight: 600;
  color: #2a5334;
  font-size: 14px;
}

/* -------brands----- */
.slider-container img {
  max-width: 10rem;
}

.brand-title h3 {
  font-family: "Merriweather", serif;
  border-left: 4px solid #509561;
  padding-left: 10px;
  margin-bottom: 0%;
}

/* ------testimonial------ */
.tesimonial-bg {
  background: url('../public/assets/testimonial.webp') no-repeat center;
  background-size: cover;
  /* min-height: 20rem; */
}

.testimonial {
  padding: 3rem;
}

.t-icon {
  color: #2a5334;
  font-size: 2.4rem;
}

.t-slider h6 {
  text-align: center;
  color: #412a26;
  font-weight: 600;
  font-size: 1.3em;
  font-style: italic;
  line-height: 1.5;
}

.t-slider p {
  color: #265d83;
  font-weight: 600;
}

/* ------Contact------ */


.contact-form {
  padding: 2rem 2.5rem;
  /* border: 1px solid #e6eebf; */
  /* border-radius: 15px; */
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.15);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.contact-form h3 {
  font-family: "Merriweather", serif;
  /* font-weight: 600; */
  font-size: 35px;
  color: #412a26;

}

.Contact-field {
  background-color: white;
  border: 1px solid #e2e0e0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Contact-field .field-icon {
  font-size: 25px;
  color: #a6908c;
}

.contact-btn button {
  background-color: #509561;
  color: white;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  width: 100%;
  border-radius: 0%;
}

input,
textarea {
  border-radius: 0%;
  border: none;
  width: 100%;
}

input:focus-visible,
textarea:focus-visible {
  border: none !important;
  outline: none !important;
}

input {
  height: 3.2rem;
}

input::placeholder,
textarea::placeholder {
  color: #a6908c;
  letter-spacing: 0.05em;
  margin: 10px;
}

/* .Contact-field{
  background-color: white;
} */

/* -----footer----- */
.footer-bg {
  /* background: url('../public/assets/footer-bg.png') no-repeat center; */
  background-color: #3b5533;
  background-size: 100% 100%;
}

.footer-logo img {
  max-height: 6rem;
}

.footer-quote {
  line-height: 1.4em;
  letter-spacing: 0.04em;
  font-size: 16px;
  color: white;
  margin-top: 10px;
}

.footer-links div {
  line-height: 1.9em;
  letter-spacing: 0.04em;
  font-size: 16px;
}

.footer-links a {
  text-decoration: none;
  color: white;
}

.footer-links a:hover {
  color: #509561;
}

.footer-links h5 {
  font-family: "Merriweather", serif;
  letter-spacing: 0.04em;
  border-bottom: 1px solid #B2BE59;
  width: fit-content;
  margin-bottom: 15px;
}

.footer-link-icon {
  color: #B2BE59;
  font-size: 20px;
  margin-right: 5px;
}

.book-appointment-footer a {
  text-decoration: none;
  color: white;
  letter-spacing: 0.05em;
  font-size: 16px;
}

.book-appointment-footer a:hover {
  color: white;
}

.book-appointment-footer {
  border: 2px solid #509561;
  width: fit-content;
  margin: 12px 0;
}

.book-appointment-footer:hover {
  background-color: #509561;
  transition: all .3s;
}

.mini-footer {
  background-color: #509561;
  text-align: center;
  padding: 10px 5px;
  color: #344330;
  letter-spacing: 0.02em;
}

@media (max-width:1100px) {
  .nav-item a {

    font-size: 12px;
  }
}

@media (max-width:1200px) {
  .header {
    padding: 0 3rem;
  }

  .container {
    padding: 5rem 1rem;
  }
}

@media (max-width:800px) {
  .header {
    padding: 0 3rem;
  }

  .container {
    padding: 5rem 2rem;
    max-width: unset !important;

  }

  .tesimonial-bg {
    background-size: cover;
  }
}

@media (max-width:450px) {
  .header {
    padding: 0 1.5rem;
  }

  .container {
    padding: 5rem 1rem;
  }

  h1 {
    font-size: 2.8rem !important;
  }

  .home-content h1,
  .home-content p {
    text-align: center;
  }

}